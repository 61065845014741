.regis-container {
    /* flex-direction: column; */
    /* display: flex; */
    /* align-items: center; */
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    height: auto;
    background-image: url("../assets/background.png");
    background-repeat: 'no-repeat';
    background-size: 'cover';
}

.regis-model {
    width: 70%;
    /* padding: 2rem; */
    backdrop-filter: 'blur(5px)';
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
}

.regis-form {
    /* padding-left: 2rem; */
    /* padding-right: 2rem; */
    padding: 1rem;

    .register-input-name {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;

        .input-prefix {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 1rem;
            width: 20%;
        }

        .input-text {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 1rem;
            width: 40%;
        }

        .regis-input-text {
            width: 100%;
        }
    }

    .register-input-tel {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;

        .input-tel-re {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 1rem;
            width: 40%;
        }

        .input-text {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 1rem;
            width: 40%;
        }

        .button-otp {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 1rem;
            width: 20%;
        }

        .regis-button-otp {
            width: 100%;
        }

        .regis-input-text {
            width: 100%;
        }
    }

    .register-input {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;

        .input-text {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 1rem;
            width: 50%;
        }

        .regis-input-text {
            width: 100%;
        }
    }

    .register-input-pomise {
        padding-top: 1rem;
        width: 100%;

        .check-text-two {
            padding-left: 30%;
            width: 100%;
        }
    }

    .register-input-address {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;

        .check-text {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 1rem;
            width: 40%;
        }
    }

    .register-input-submit {
        padding-top: 3rem;
        justify-content: center;
    }
}

.divider {
    width: 100%;
    padding-bottom: 2rem;
}


label {
    font-family: 'Kanit';
}

p {
    font-family: 'Kanit';
}

h1 {
    font-family: 'Kanit';
    text-align: center;
}

button {
    font-family: 'Kanit';
}


@media only screen and (max-width: 430px) {
    .regis-container {
        height: auto;
    }

    .regis-model {
        width: 90%;
    }

    .regis-form {
        /* text-align: center; */

        .register-input-name {
            padding: 0rem;

            .input-prefix {
                width: 100%;
            }

            .input-text {
                padding-top: 0rem;
                width: 100%;
            }

            .regis-input-text {
                width: 100%;
            }
        }

        .register-input-tel {
            padding: 0rem;

            .input-tel-re {
                width: 100%;
            }

            .input-text {
                width: 100%;
            }

            .button-otp {
                width: 100%;
            }

            .regis-button-otp {
                width: 100%;
            }

            .regis-input-text {
                width: 100%;
            }
        }

        .register-input {
            padding: 0rem;

            .input-text {
                width: 100%;
            }

            .regis-input-text {
                width: 100%;
            }
        }

        .register-input-pomise {
            padding-top: 1rem;

            .check-text-two {
                padding-left: 5%;
                padding-bottom: 10%;
            }
        }


        .register-input-address {
            padding-bottom: 1rem;

            .check-text {
                padding-bottom: 1rem;
                width: 100%;
            }
        }

        .register-input-submit {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }

}