.containerlogin {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url("../assets/background.png");
    background-repeat: 'no-repeat';
    background-size: 'cover';
}

.containerinput {
    padding-top: 20px;
}

.inputtext {
    padding-bottom: 30px;
}


@media only screen and (max-width: 430px) {
    .containerinput {
        /* padding-top: 30px; */
    }
}


@media only screen and (max-width: 375px) {
    .containerinput {
        padding-top: 10px;
    }
}