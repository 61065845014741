@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.history-model {
    width: 100%;
    height: 70vh;
}

.history-field {
    font-family: 'Kanit', sans-serif;

    .history-datatable {
        font-family: 'Kanit', sans-serif;
        width: 100%;
    }
}

@media only screen and (max-width: 430px) {
    .history-model {
        width: 100%;
    }
}