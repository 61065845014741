.order-container {
	justify-content: center;
	padding-top: 1rem;
	padding-bottom: 3rem;
	padding-left: 16rem;
}

.order-model {
	width: 100%;
	height: 90vh;
}

.order-visible {
	text-align: center;
	width: 40%;
}

label {
	display: block;
	/* margin-top: 1rem; */
}

.package-image {
	width: 50%;
}

@media only screen and (max-width: 430px) {
	.order-container {
		padding-left: 0;
	}

	.order-model {
		height: auto;
	}

	.order-visible {
		width: 80%;
	}

	.package-image {
		width: 100%;
	}
}