.com-container {
	justify-content: center;
	padding-top: 1rem;
	padding-bottom: 3rem;
	padding-left: 16rem;
}

.com-draw-two {
	width: 30%;
}

.com-draw-one {
	width: 70%;
}

.com-scroll {
	width: 100%;
	height: 70vh;
}

.com-card {
	font-family: 'Kanit', sans-serif;
	text-align: center;
	font-size: 20px;
}

.com-model {
	font-family: 'Kanit', sans-serif;
}

.com-button {
	font-family: 'Kanit';
	font-size: 14px;
	margin-top: 1rem;
}

.com-button:hover {
	color: purple;
	font-weight: bold;
	cursor: pointer;
	border-color: purple;
}

@media only screen and (max-width: 430px) {
	.com-draw-two {
		width: 80%;
	}

	.com-draw-one {
		width: 90%;
	}

	.com-scroll {
		height: 65vh;
	}
}