@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Add this CSS to your styles */
div:where(.swal2-container) {
	display: none;
	z-index: 5000 !important;
}

input,
p {
	font-family: 'Kanit', sans-serif;
}

body {
	font-family: 'Kanit', sans-serif;
	background: #646464;
	overflow-x: hidden;
}

button {
	font-family: 'Kanit', sans-serif;
	border-color: none;
}

.p-dialog {
	font-family: 'Kanit', sans-serif;
}

.title {
	text-align: center;
	color: #ECD400;
	text-shadow: 2px 3px 4px #000000;
	/* font-size: xx-large; */
}

.chip {
	font-family: 'Kanit', sans-serif;
	padding-left: 1rem;
	padding-right: 1rem;
}

.container {
	font-family: 'Kanit', sans-serif;
	justify-content: center;
	/* padding-top: 1rem; */
	/* padding-bottom: 3rem; */
	/* padding-left: 16rem; */
	padding-left: 260px;
	margin-top: 10px;
}

.form {
	padding-top: 1rem;
	text-align: center;
}

.Stack {
	padding: 0.5rem;
}

.custom-InputText {
	width: 100%;
	height: 100%;
	text-align: left;
}

.custom-button {
	width: 100%;
	height: 100%;
	font-family: 'Kanit', sans-serif;
	background-color: #ECD400;
	font-weight: bold;
}

.custom-button:hover {
	text-shadow: 0px 0px 6px rgb(0, 142, 47);
	background-color: #333132;
	color: #e1e1e1;
	transition: all 0.4s ease 0s;
}

.custom-button-2 {
	width: 100%;
	height: 100%;
	font-family: 'Kanit', sans-serif;
	background-color: #ECD400;
	font-weight: bold;
}

.custom-button-2:hover {
	text-shadow: 0px 0px 6px rgb(0, 142, 47);
	background-color: #333132;
	color: #e1e1e1;
	transition: all 0.3s ease 0s;
	font-size: 130%;
}

.button-family {
	color: #565656;
	background-color: #ffffff;
	padding: 8px;
	border-radius: 5px;
}

.product {
	width: 20%;
	height: 100%;
}

.step2item {
	transition: all 0.4s ease 0s;
	width: 100%;
	height: 100%;
	padding: 1px;
	border-radius: 5px;
}

.step2item:hover {
	transition: all 0.4s ease 0s;
	font-weight: bold;
	box-shadow: 7px 3px 15px #000000;
	cursor: pointer;
}

.product-item {
	font-family: 'Kanit', sans-serif;
}

.product-item:hover {
	box-shadow: 6px 6px 15px;
	cursor: pointer;
	font-weight: bold;
}

.btn-canecl {
	background-color: red;
	font-family: 'Kanit', sans-serif;
	color: #ffffff;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
}

.add-cart {
	color: #ffffff;
	font-family: 'Kanit', sans-serif;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
}

.media-dialog {
	border-top: 3px solid #ECD400;
}

.btn-checkout {
	background-color: rgb(72, 158, 32);
	border: 'none';
	font-family: 'Kanit', sans-serif;
	color: #ffffff;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
}

.scroll {
	height: 60vh;
}

.scroll-premium {
	height: 80vh;
}

.order-platform {
	width: 50%;
}

.table-express {

	table,
	th,
	td {
		border: 1px solid black;
		border-collapse: collapse;
		padding: 0.5rem;
	}

	text-align: center;
}

/* Spinner */
.spinner {
	border: 16px solid #f3f3f3;
	/* Light grey */
	border-top: 16px solid #3498db;
	/* Blue */
	border-radius: 50%;
	width: 120px;
	height: 120px;
	animation: spin 2s linear infinite;
	margin: 0 auto;
	/* Center the spinner horizontally */
}

.file-img {
	width: 50%;
}

.express-header-tabview .p-tabview-panels {
	background-color: #858183;
}

.express-header-tabview .p-tabview-nav {
	/* background-color: #333132; */
	/* border-radius: 4px; */
}

.home-container {
	width: 100%;
	padding-left: 250px;
}

.home-img {
	width: 100%;
	padding-left: 150px;
	padding-right: 150px;
}

.home-scroll {
	height: 70vh;
}

.home-menu {
	padding: 0px;
}

.home-menu-img {
	width: 145px;
	margin-right: 20px;
}

.home-menu-img.sm {
	width: 150px;
	margin-left: 5px;
	/* background-color: red; */
}

.image-package {
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 2px dashed #ccc;
	padding: 20px;
	border-radius: 8px;
	width: 280px;
	margin: 0 auto;
}

.file-input-label {
	cursor: pointer;
	color: #555;
	font-size: 16px;
	padding: 10px 20px;
	background-color: #f5f5f5;
	border: 1px solid #ddd;
	border-radius: 4px;
	transition: background-color 0.3s ease;
}

.file-input-label:hover {
	background-color: #e9e9e9;
}

.input-image {
	display: none;
	/* Hide the default file input */
}

/* img { */
/* margin-top: 20px; */
/* border-radius: 8px; */
/* object-fit: cover; */
/* border: 2px solid #ddd; */
/* } */

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	/* Ensure it's above other elements */
}

#capture {
	width: 100%;
	position: relative;
}

.page {
	width: 6cm;
	height: 4cm;
	border: 1px solid indigo;
}

.g-block>.g-col-6 {
	display: inline-block
}

.g-col-6 {
	width: 50%
}

.g-col-12 {
	width: 100%;
}

@media only screen and (max-width: 430px) {
	.container {
		padding-left: 0;
	}

	.product {
		width: 50%;
	}

	.order-platform {
		width: 100%;
	}

	.wallet-form {
		width: 100%;
	}

	.file-input-label {
		height: 180px;
	}

	.home-container {
		height: 92vh;
		padding-left: 0px
	}

	.home-img {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
}