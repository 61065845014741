.login-container {
	justify-content: center;
	padding-top: 1rem;
	padding-bottom: 3rem;
}

.login-model-one {
	width: 30%;
}

.login-model-two {
	width: 40%;
}

.login-scroll {
	width: 100%;
	height: 80vh;
	font-family: 'Kanit', sans-serif;
}

@media only screen and (max-width: 430px) {
	.login-model-one {
		width: 100%;
	}

	.login-model-two {
		width: 100%;
	}

	.login-scroll {
		height: 75vh;
	}
}