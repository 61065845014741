.tricket-container {
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 3rem;
}

.tricket-model {
    width: 60%;
}

.tricket-form {
    padding-top: 1rem;
    padding-left: 1rem;
    text-align: center;

    .tricket-data-form {
        width: 100%;
        background-color: red;
    }

    .tricket-button {
        /* padding-top: 1rem; */
    }

    .tricket-img {
        width: 100%;
        padding-left: 1rem;
    }

}

.tricket-scroll {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 60vh;
    background-color: 'transparent';
}

.tricket-package {
    /* padding: 1rem; */
    padding-left: 2rem;
    padding-right: 2rem;
    /* padding-top: 1rem; */
    padding-bottom: 1rem;
    background-color: white;

    .tricket-package-logo {
        padding-top: 1.5rem;
        width: 15%;
        text-align: center;

        .tricket-package-img {
            width: 100%;
        }
    }

    .tricket-package-name {
        padding-top: 0.5rem;
        justify-items: center;
        text-align: center;
        width: 100%;
    }

    .tricket-package-name-o {
        padding-top: 1.5rem;
        margin-left: 10%;
        justify-items: center;
        text-align: left;
        width: 35%;
    }

    .tricket-package-name-r {
        padding-top: 1.5rem;
        margin-left: 5%;
        justify-items: center;
        text-align: left;
        width: 20%;
    }

    .tricket-package-price {
        /* margin-left: 30%; */
        padding-top: 3rem;
        width: 20%;
        text-align: center;
    }

    .tricket-package-button {
        padding-left: 5%;
        padding-top: 3.5rem;
    }
}

.tricket-order-one {
    width: 20%;
}

.tricket-order-two {
    width: 40%;
}

.tricket-order-three {
    width: 40%;
}

.tricket-net-one {
    width: 50%;
}

.tricket-net-two {
    width: 50%;
}

.tricket-net-three {
    width: 100%;
}

.tricket-button {
    font-family: 'Kanit', sans-serif;
    border-radius: 1rem;
}

.dialog-form {
    width: 50%;
}
.button-family-2 {
    padding-top: 1rem;
}


@media only screen and (max-width: 430px) {
    .tricket-form {
        padding-top: 0;
        padding-left: 0;
    }

    .tricket-model {
        width: 100%;
    }

    .tricket-package {
        padding-left: 0rem;
        padding-right: 0rem;
        padding-top: 1rem;
        padding-bottom: 2rem;

        .tricket-package-logo {
            width: 100%;

            .tricket-package-img {
                width: 30%;
            }
        }

        .tricket-package-name-o {
            width: 100%;
            margin-left: 0;
            padding-top: 1rem;
            text-align: center;
        }

        .tricket-package-name-r {
            width: 100%;
            margin-left: 0;
            padding-top: 1rem;
            text-align: center;
        }

        .tricket-package-price {
            /* margin-left: 30%; */
            padding-top: 1rem;
            width: 100%;
            text-align: center;
        }

        .tricket-package-button {
            width: 100%;
            align-items: center;
            padding-top: 1rem;
            margin-right: 1rem;
        }
    }

    .tricket-order-one {
        width: 100%;

        .tricket-img {
            width: 40%;
        }
    }

    .tricket-order-two {
        width: 100%;
    }

    .tricket-order-three {
        width: 100%;
    }

    .tricket-net-one {
        width: 100%;
    }

    .tricket-net-two {
        width: 100%;
    }

    .tricket-net-three {
        width: 100%;
    }

}