
.bg-custom-gradient {
  background-color: #F4CE14;
  background-image: linear-gradient(to right, #F4CE14, #FFC100, #FFF455);
}

.border-custom-color {
  border-color: #FDA403;
}

.text-custom-white {
  color: #FFFFFF;
}
.bg-custom-yellow {
  background-color: #FAA300;
  border: none;
}
.card {
    background: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 2px 3px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 0 10px;
  }
  
  .card-wrapper {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
  }
  
  .card-icon {
    width: 20%;
  }
  
  .card-icon .icon-cart-box {
    background-color: #e0f2f1;
    width: 3em;
    height: 3em;
    border-radius: 50%;
    text-align: center;
    padding: 15px 0px;
    margin: 0 auto;
  }
  
  .card-content {
    width: 80%;
  }
  
  .card-title-wrapper {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: baseline;
    width: 100%;
  }
  
  .card-title {
    width: 95%;
    font-size: 1em;
    font-weight: 600;
    color: #333;
    padding: 20px 0 0 10px;
  }

  
  .product-name {
    font-size: 0.8em;
    color: #757575;
    padding: 10px 0 0 10px;
  }
  
  .product-name:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  
  .product-price {
    font-size: 0.9em;
    font-weight: 600;
    color: #333;
    padding: 0 0 10px 10px;
  }
  
  .btn-view-cart {
    font-size: 0.7em;
    font-weight: 600;
    padding: 5px 10px;
    margin: 5px 10px 20px;
    border-radius: 15px;
    color: #009688;
    border: 1px solid #009688;
    background-color: #e0f2f1;
    box-shadow: none;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.4s ease-in-out;
  }
  
  .btn-view-cart:hover,
  .btn-view-cart:active,
  .btn-view-cart:focus {
    color: white;
    background-color: #009688;
    border: 1px solid #009688;
  }
  