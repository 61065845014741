.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-wrap {
    width: 80%;
    display: flex;
    gap: 1rem;
    row-gap: 3rem;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

.flex-wrap div {
    min-width: 300px;
}

.text-yellow {
    color: #ECD400;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.bg-yellow {
    background-color: #ECD400;
}

.border {
    border: 1px solid white;
}

.container {
    height: screen;
}

.container-flex {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 3rem;
}

.item {
    overflow: hidden;
}

.item img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.item:hover {
    box-shadow: 5px 3px 10px rgb(31, 29, 29);
    border: 2px solid #a79601;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
}

.item-details {
    width: fit-content;
}

.detail-block {
    width: 300px;
    height: 8rem;
    background-color: white;
    border-radius: 20px;
}

.detail-name {
    color:aliceblue;
    font-size: large;
    font-weight: normal;
    text-align: center;
}

.input-tel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 0rem 0.5rem;
    margin: 0px;
}

.input-tel p {
    color: whitesmoke;
    padding: 0px;
    margin: 0px;
}

.input-tel input {
    padding: 0.5rem 0.5rem;
    border-radius: 5px;
    font-size: 40px;
    text-align: center;
}

.price-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
    row-gap: 0.5rem;
    padding: 0.5rem 0.5rem 5rem 0.5rem;
}

.price {
    background-color: rgb(253, 255, 241);
    text-align: center;
    padding: 0.5rem 0px;
    border-radius: 5px;
    cursor: pointer;
    flex: 1 1 100px;
    max-width: 100px;
}

.price:hover {
    border: 2px solid #a79601;
    font-weight: bold;
}

.price::before {
    content: '฿';
    padding-right: 0.25rem;
}

.selected {
    font-weight: bold;
    background-color: #fdf397;
    border: 2px solid #a79601;
}

.btn-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 5rem;
}

.btn-next {
    padding: 1rem 2rem;
    font-size: large;
    font-weight: bold;
    color: rgb(31, 30, 30);
    background-color: #ECD400;
    border-radius: 20px;
    border: 0px;
    transition: all 0.3s ease-in-out;
    width: 100%;
}

.btn-anable:hover {
    box-shadow: 5px 5px 5px rgba(43, 42, 42, 0.781);
    transition: all 0.3s ease-in-out;
    outline: 2px solid #a79601;
    outline-offset: 2px;
}

.btn-anable {
    cursor: pointer;
}

.btn-disabled {
    opacity: 50%;
}

.invalid-input {
    border: 3px solid rgb(161, 52, 52);
    background-color: rgb(228, 170, 170);
}

.valid-input {
    border: 3px solid rgb(2, 158, 35);
    background-color: rgb(150, 211, 158);
}

.invalid-text {
    color: red;
}

.hidden {
    opacity: 0%;
}

.checkout-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3rem 0;
    gap: 0.5rem;
}

.checkout-details {
    background-color: white;
    width: fit-content;
    padding: 1rem 2rem;
    border-radius: 20px;
}

.checkout-details p {
    margin: 0;
    padding: 0;
    font-size: 28px;
}

.checkout-details h2 {
    margin: 0;
    padding: 0.5rem 0rem;
    font-size: 40px;
    font-weight: bold;
}

.checkout-details span {
    margin: 0;
    padding: 0.5rem 0.25rem;
    font-weight: bold;
    color: #ffe602;
    text-shadow: 0px 1px 1px #0e0e0e;
}

@media screen and (max-width: 480px) {
    .checkout-details p {
        margin: 0;
        padding: 0;
        font-size: 18px;
    }
    
    .checkout-details h2 {
        margin: 0;
        font-size: 20px;
        font-weight: bold;
    }
}

.paymethod-container {
    background-color: azure;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 2rem;
    border-radius: 20px;
    flex-grow: 1;
    flex-basis: 350px;
    cursor: pointer;
    position: relative;
}

.paymethod-container:hover {
    box-shadow: 5px 5px 5px #171616;
    border: 5px solid #ded300;
}

.selected {
    border: 5px solid #84d645;
    background-color: #c1f599;
}

.paymethod-logo {
    background-color: rgb(226, 223, 5);
    border-radius: 5px;
    padding: 3rem;
}

.paymethod-details {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 50%;
}

.paymethod-details p {
    padding: 0;
    margin: 0;
    font-size: 40px;
}

.member {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 1rem 0rem;
    gap: 0.5rem;
    align-items: center;
}

.member label {
    text-align: left;
    width: 20rem;
    font-weight: bold;
}

.member input {
    padding: 0.5rem 0.5rem;
    text-align: center;
    font-size: 24px;
    width: 20rem;
}