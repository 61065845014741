.menubar {
	width: 100%;
	height: 50px;
	background-color: #333;
	display: flex;
	justify-content:space-between;
	
}

.sidebar {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 250px;
	background-color: #333;
	padding-top: 20px;
	transform: translateX(-100%);
	transition: transform 0.3s ease;
	z-index: 999;
	padding-bottom: 3rem;
}

.sidebar.open {
	transform: translateX(0);
}

.logo1 {
	width: 150px;
	margin-left: 50px;
}

.logo2 {
	width: 150px;
	margin-left: 50px;
	position: sticky;
	top:0;
}

.toggle-btn {
	padding: 12px;
	color: white;
}

.overflow-y-auto {
	overflow-y: auto;	
}
.sidebar-head {
	position: sticky;
	top:0;
	z-index: 10;
}

/* Responsive styles */
@media screen and (max-width: 430px) {
	.sidebar {
		width: 230px;
	}

	.logo1 {
		width: 50px;
		padding: 5px;
		margin-left: 0px;
	}

	.logo2 {
		width: 150px;
		margin-left: 40px;
	}
}