.custom-input {
    width: 100%; 
 
    border: 2px solid #E5E7EB; 
    border-radius: 0.375rem;
    transition: border-color 0.2s; 
  }

  .custom-input:focus {
    border-color: #6366F1; 
    outline: none; 
  }

  .button {
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #F4CF15; 
    color: #0C0C0C; 
    padding: 0.5rem 0.75rem; 
    font-weight: 600; 
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    border-color: #f9e5a4; 

  }
  
  .button:hover {
    background-color: #f7e06e; 
  }
  
  .button:focus {
    background-color: #e9c149; 
    outline: none;
  }
  