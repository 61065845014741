.profile-container {
    justify-content: center;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 16rem;
    height: 98vh;
}

.profile-model {
    width: 70%;
    height: 100%;
    padding: 2rem;
    backdrop-filter: 'blur(5px)';
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
}

.profile-img {
    width: 30%;
}

.profile-form {
    padding: 1rem;
    /* background-color: red; */

    .profile-number {
        padding-top: 1rem;
        width: 100%;
        font-size: 16px;
        font-style: italic;
    }

    .profile-name {
        padding-top: 1rem;
        width: 100%;
        font-size: 14px;
    }

    .profile-button-edit {
        padding-top: 1rem;
        width: 100%;
    }

    .profile-data {
        margin-top: 2rem;
        margin-left: 20%;
        width: 60%;
    }
}


@media only screen and (max-width: 430px) {
    .profile-container {
        /* height: auto; */
        padding-left: 0;
        height: 92vh;
    }

    .profile-model {
        width: 80%;
    }

    .profile-img {
        width: 80%;
    }

    .profile-form {
        padding: 0rem;

        .profile-card {
            margin-top: 0rem;
            margin-left: 1rem;
            margin-right: 0.5rem;
            width: 100%;
        }

        .profile-data {
            margin-left: 0;
            width: 100%;
        }
    }

}